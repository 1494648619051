@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300&display=block);


:root {
  /* Base font size for the typography scale. */
  font-size: 16px;
  line-height: 1.35;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  overflow-x: hidden;
}

html,
body,
#root,
[data-reactroot] {
  min-height: 100vh;
}

body {
  background-color: #f8f9fd;
}

p {
  margin: 0;
}

a,
button,
div,
input,
textarea,
fieldset {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

button,
fieldset {
  -webkit-appearance: none;
          appearance: none;
  background-color: transparent;
  background-color: initial;
  border: none;
  margin: 0;
  margin: initial;
  padding: 0;
  padding: initial;
  text-decoration: none;
}

button:not(:disabled) {
  cursor: pointer;
}

.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.htValid:not(.htInvalid) {
  background-color: #b1f3e5;
}

.react-flow__controls-button {
  transition: 0.1s ease-in-out;
  color: var(--colors-dark500) !important;
}

.react-flow__controls-button:hover {
  transition: 0.1s ease-in-out;
  color: var(--colors-dark700) !important;
}

#traits-container,
#styles-container,
#traits-container-en_US,
#styles-container-en_US {
  --border-radius: 4px;

  --input-gap: 10px;
  --input-padding: 10px;
  --input-height: 40px;
  --input-units-width: 55px;

  --wrapper-vertical-padding: 20px;
  --wrapper-horizontal-padding: 10px;

  --label-margin-bottom: 10px;

  --color-input-preview-size: 24px;

  --property-margin-bottom: 18px;

  --transition-fluid: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.gjs-fields {
  width: 100%;
}
.gjs-field {
  background-color: var(--colors-light100);
  display: flex;
  flex: 1 1;
}

.gjs-fields .gjs-field select,
.gjs-field.gjs-field-text input[type="text"],
.gjs-input-holder input[type="text"] {
  transition: var(--transition-fluid);
  background-color: var(--colors-light100);
  border-radius: var(--border-radius);
  border: 1px solid var(--colors-dark100);
  color: 1px solid var(--colors-dark900);
  height: var(--input-height);
  padding-left: var(--input-padding);
}
.gjs-field.gjs-field-text input[type="text"]:hover,
.gjs-field.gjs-field-text input[type="text"]:focus,
.gjs-fields .gjs-field select:hover,
.gjs-fields .gjs-field select:focus,
.gjs-input-holder input[type="text"]:hover,
.gjs-input-holder input[type="text"]:focus {
  border: 1px solid var(--colors-dark300);
}

.gjs-input-holder,
.gjs-field-wrp,
.gjs-field.gjs-select > span,
.gjs-sm-input-holder {
  width: 100%;
}
/* arrow for select input */
.gjs-field.gjs-select .gjs-sel-arrow {
  z-index: 1;
  right: var(--input-padding);
}
.gjs-field.gjs-select .gjs-sel-arrow .gjs-d-s-arrow {
  border-top-color: var(--colors-dark100);
}
.gjs-sel-arrow .gjs-d-s-arrow:hover {
  border-top-color: var(--colors-dark300);
}

.gjs-field-arrows .gjs-field-arrow-u {
  border-bottom-color: var(--colors-dark600);
}
.gjs-field-arrows .gjs-field-arrow-u:hover {
  border-bottom-color: var(--colors-dark900);
}
.gjs-field-arrows .gjs-field-arrow-d {
  border-top-color: var(--colors-dark600);
}
.gjs-field-arrows .gjs-field-arrow-d:hover {
  border-top-color: var(--colors-dark900);
}

.gjs-field.gjs-field-integer input[type="text"] {
  padding-right: 40px;
}

.gjs-field.gjs-field-integer .gjs-field-arrows {
  right: calc(var(--input-padding) + var(--input-units-width));
}

/* dropdown alongside input*/
.gjs-field .gjs-field-units {
  position: static;
  width: var(--input-units-width);
  margin-left: var(--input-gap);
}

.gjs-fields .gjs-sm-field .gjs-sm-property {
  border-bottom: none;
}

.gjs-sm-sector .gjs-sm-property .gjs-sm-btn,
.gjs-clm-tags .gjs-sm-property .gjs-sm-btn {
  transition: var(--transition-fluid);
  background-color: var(--colors-accent400);
  border-radius: var(--border-radius);
  box-shadow: 0px 10px 20px rgba(var(--colors-accent600rgb), 0.3);
  color: var(--colors-light100);
  font-family: var(--fonts-normal);
  font-size: var(--fontSizes-12);
  font-weight: var(--fontWeights-bold);
  height: 30px;
  line-height: var(--lineHeights-normal);
  text-shadow: none;
}
.gjs-sm-sector .gjs-sm-property .gjs-sm-btn:hover,
.gjs-sm-sector .gjs-sm-property .gjs-sm-btn:hover,
.gjs-clm-tags .gjs-sm-property .gjs-sm-btn:focus,
.gjs-clm-tags .gjs-sm-property .gjs-sm-btn:focus {
  box-shadow: 0px 13px 30px rgba(var(--colors-accent600rgb), 0.5);
  background-color: var(--colors-accent500);
}

.gjs-sm-property.gjs-sm-color.gjs-sm-property__background-color {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.gjs-field.gjs-field-color {
  position: relative;
}

.gjs-field.gjs-field-color .gjs-field-colorp {
  border: none;
  position: absolute;
  right: var(--input-padding);
  top: 50%;
  transform: translateY(-50%);
  width: var(--color-input-preview-size);
  height: var(--color-input-preview-size);
}
.gjs-field.gjs-field-color .gjs-field-colorp .gjs-field-colorp-c,
.gjs-field.gjs-field-color .gjs-field-colorp .gjs-checker-bg,
.gjs-field.gjs-field-color .gjs-field-colorp .gjs-field-color-picker {
  border-radius: 50% !important;
}
.gjs-field.gjs-field-color .gjs-field-colorp .gjs-field-color-picker {
  border-radius: 50%;
}

.sp-container {
  border: none;
  border-radius: var(--border-radius);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px;
}

.gjs-sm-label, .gjs-label-wrp {
  margin-top: 0;
  margin-bottom: var(--label-margin-bottom);
}
.gjs-sm-label span, .gjs-label-wrp div {
  color: var(--colors-dark500);
  font-family: var(--fonts-normal);
  font-size: var(--fontSizes-10);
  font-weight: var(--fontWeights-bold);
  letter-spacing: var(--letterSpacings-normal);
  line-height: var(--lineHeights-normal);
  text-transform: uppercase;
}

.gjs-cv-canvas {
  height: 100%;
  top: 0;
  width: 100%;
}

.gjs-badge {
  background-color: transparent;
  color: var(--colors-primary400);
}

/* Toolbar */
.gjs-toolbar {
  border-radius: var(--border-radius);
  background-color: rgb(252, 252, 255);
  box-shadow: 0px 10px 13px 0px rgba(10, 25, 39, 0.09);
}

.gjs-toolbar > div {
  display: flex;
}

.gjs-toolbar-item {
  color: #a1a4b8;
  font-size: 16px;
  padding: 10px 5px;
  transition: 0.3s ease all;
  width: auto;
}

.gjs-toolbar-item:first-child {
  padding-left: 10px
}

.gjs-toolbar-item:last-child {
  padding-right: 10px
}

.gjs-toolbar-item:hover {
  color: #26272d;
}

/* Canvas */
.gjs-cv-canvas .gjs-highlighter, .gjs-cv-canvas .gjs-highlighter-sel {
  outline: 1px solid var(--colors-primary400) !important;
}

/* Panel */
.gjs-pn-panel {
  padding: 0;
  position: relative;
  width: 100%;
}

.gjs-one-bg {
  background-color: #fff;
}

.gjs-two-color {
  color: #26272d;
}

.gjs-four-color, .gjs-four-color-h:hover  {
  color: #26272d;
}

/* Block */
.gjs-blocks-c {
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-columns: 1fr 1fr;
  margin-top: 15px;
}

.gjs-block {
  align-items: center;
  border-radius: var(--border-radius);
  border: 1px solid #e5e5ee;
  box-shadow: none;
  color: #a1a4b8;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  justify-content: center;
  margin: 0;
  padding: 0;
  transition: 0.3s ease all;
  width: 100%;
}

.gjs-block-section {
  grid-column: span 2;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}

.gjs-block:hover {
  border-color: transparent;
  box-shadow: 0px 10px 13px 0px rgba(10, 25, 39, 0.08);
  color: #26272d;
}

.gjs-block-label {
  width: 100%;
}

.gjs-placeholder {
  border-color: transparent var(--colors-primary400) !important;
}

.gjs-placeholder-int {
  background-color: var(--colors-primary400) !important;
}

.gjs-sm-header {
  color: #26272d;
}

/* Layer */

.gjs-layer {
  background-color: rgba(254, 218, 214, 0.2);
  color: #26272d;
}

.gjs-layer-vis {
  display: none;
  pointer-events: none;
}

.gjs-layer-title {
  background-color: #fff;
  margin-left: -10px;
}

.gjs-layer-move {
  color: #a1a4b8;
  top: 10px;
  left: 5px;
  width: 20px;
}

.gjs-layer-count {
  color: #a1a4b8;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.025em;
  top: 15px;
  right: 15px;
}

.gjs-layer-name {
  color: #26272d;
  font-size: 12px;
  letter-spacing: -0.025em;
  line-height: 1;
  padding-top: 0;
  padding-bottom: 0;
}

.gjs-layer-title {
  border-bottom-color: #f3f3f9;
  padding-top: 15px;
  padding-bottom: 15px;
}

.gjs-layer.gjs-hovered .gjs-layer-title {
  background-color: rgba(218, 198, 255, 0.2);
}

.gjs-layer.gjs-selected .gjs-layer-title {
  background-color: rgba(218, 198, 255, 0.5);
}

.gjs-layer-caret {
  color: #a1a4b8;
  top: 0;
  left: -12px; /* Set through trial and error */
}

/* Sector */
.gjs-sm-sector.gjs-sm-open {
  border-bottom: none;
}

.gjs-sm-sector .gjs-sm-title {
  background-color: transparent;
  border-bottom: 1px solid #f3f3f9;
  color: #26272d;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.025em;
  line-height: 1.2;
  padding: 25px 30px;
  pointer-events: none;
}

.gjs-sm-sector .gjs-sm-title .fa {
  display: none;
}

.gjs-sm-property .gjs-sm-label {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.025em;
  line-height: 1;
}

.gjs-sm-sector > .gjs-sm-properties > .gjs-sm-property {
  margin-bottom: 0;
  padding: 20px 30px;
}

.gjs-sm-properties > .gjs-sm-property:not(:last-child) {
  border-bottom-color: #f3f3f9;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.gjs-sm-sector .gjs-sm-property {
  float: none;
  width: 100%;
}

.gjs-sm-composite .gjs-sm-properties {
  padding: 0;
}

/* Resizer Picker */
[class*="gjs-resizer"] {
  display: none;
}

.gjs-trt-trait {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.gjs-sm-sector > .gjs-sm-properties > .gjs-sm-property {
  padding: var(--wrapper-vertical-padding) var(--wrapper-horizontal-padding);
}

.gjs-sm-sector .gjs-sm-property, .gjs-clm-tags .gjs-sm-property {
  margin-bottom: var(--property-margin-bottom);
}
.gjs-sm-sector .gjs-sm-property:last-of-type, .gjs-clm-tags .gjs-sm-property:last-of-type {
  margin-bottom: 0;
}

.gjs-fields > .gjs-sm-field.gjs-sm-composite {
  border: none;
}

/* input block wrapper starts - eg. padding, border radius */
.gjs-fields > .gjs-sm-field.gjs-sm-composite {
  background-color: var(--colors-light100);
}
/* input block wrapper ends */

/* Temp fix, RTE dropdown panel position */
.cke.cke_panel.cke_ltr {
  top: -137px !important;
}

.gjs-radio-items {
  border-radius: var(--border-radius);
  border: 1px solid var(--colors-light700);
}

.gjs-radio-items .gjs-radio-item {
  align-items: center;
  background-color: var(--colors-light200);
  color: var(--colors-dark500);
  display: flex;
  flex: 1 1;
  border-left-color: var(--colors-light100);
  border-right: 1px solid var(--colors-light700);
  font-size: var(--fontSizes-12);
  font-weight: var(--fontWeights-bold);
  justify-content: center;
}

.gjs-radio-items .gjs-radio-item .gjs-radio-item-label {
  width: 100%;
  padding: 10px 6px;
}

.gjs-radio-items .gjs-radio-item:last-of-type {
  border-right: none;
}

.gjs-radio-items .gjs-radio-item:hover {
  color: var(--colors-dark700);
}

.gjs-radio-item input:checked+.gjs-radio-item-label {
  background-color: var(--colors-light100);
  color: var(--colors-primary300);
  box-shadow: 0px 2px 3px rgba(var(--colors-dark600rgb), 0.1);
}

.gjs-radio-item input:checked+.gjs-radio-item-label:hover {
  color: var(--colors-primary300);
}



/* Document
 * ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */

html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
 * ========================================================================== */

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */

main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * Show the overflow in Edge 18- and IE.
 */

input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */

textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* User interaction
 * ========================================================================== */

